import { Box, Button, Card, CardMedia, CircularProgress, Dialog, DialogActions, Grid, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { BASE_URL, model } from '../apis/urls';
import { getRequest } from '../apis/requests';
import { Analytics, BatchPrediction } from '@mui/icons-material';
import ImagePredictModal from '../components/model/ImagePredictModal';
import { formatDate } from '../utils';

const getGreenToRed = (percent) => {
    if (percent) {
        let r = percent<70 ? 255 : Math.floor(255-(percent*2-100)*255/140);
        let g = percent>70 ? 200 : Math.floor((percent*2)*200/140);
        return 'rgb('+r+','+g+',0)';
    }
    return "#eeeeee";
}

const learningAlgorithmsDict = {
    "logistic_regression": "LOGISTIC REGRESSION",
    "k_neighbors_classifier": "K-NEIGHBORS",
    "support_vector_classifier": "SVM",
    "decision_tree_classifier": "DECISION TREE",
    "random_forest_classifier": "RANDOM FORESTS",
    "gradient_boosting_classifier": "GBM",
    "ada_boost_classifier": "ADA BOOST",
    "naive_bayes_classifier": "NAIVE BAYES",
    "mlp_classifier": "MLP",
    "linear_regression": "LINEAR REGRESSION",
    "lasso_regression": "LASSO",
    "elastic_net": "ELASTIC NET",
    "k_neighbors_regressor": "K-NEIGHBORS",
    "support_vector_regressor": "SVM",
    "decision_tree_regressor": "DECISION TREE",
    "random_forest_regressor": "RANDOM FORESTS",
    "gradient_boosting_regressor": "GBM",
    "mlp_regressor": "MLP",
    "yolov8": "YOLO v8",
    "yolov9": "YOLO v9",
    "yolov10": "YOLO v10"
}

const GradientCircularProgress = ({ value }) => {
    
  
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          {/* Background Circular Progress (gray) */}
          <CircularProgress
            variant="determinate"
            value={100}
            thickness={5}
            sx={{
              color: '#eeeeee', // Gray color for the background
              position: 'absolute',
              left: 0,
            }}
          />
          {/* Foreground Circular Progress (color based on value) */}
          <CircularProgress
            variant="determinate"
            value={value}
            thickness={5}
            sx={{
              color: getGreenToRed(value),
            }}
          />
        </Box>
      );
  };

const Overview = ({modelData}) => {

    const theme = useTheme();

    const valResults = modelData["image_val_results"];
    console.log(modelData)


    return (
        <Box sx={{display:"flex", flexDirection:"column", padding:"20px", gap:"20px"}}>
            <Box sx={{display:"flex", 
                      flexDirection:"column", 
                      padding:"20px",
                      border: "1px solid gray",
                      borderRadius: "5px"}}>
                <Typography variant='h6'>{modelData["name"]} Summary</Typography>
                <Typography variant='subtitle1' fontWeight={300}>This machine learning model is trained for <b>{modelData["target_type"].replace("_", " ")}</b> task with <b>{(100 * modelData["last_test_performance"]).toFixed(2)}% {modelData["optimization_metric"]}</b> as validation performance.</Typography>
                <Box sx={{display:"flex", flexDirection:"row", gap:"20px", marginTop:"10px"}}>
                    <Box sx={{flex:1, 
                        display:"flex", 
                        flexDirection:"column", 
                        padding:"10px", 
                        backgroundColor:theme.palette.background.lighter,
                        border:"1px solid gray",
                        borderRadius:"5px"}}>
                        <Typography>Overview</Typography>
                        <Typography variant='subtitle2' fontWeight={300} fontSize={"9pt"}>Overview of your machine learning model</Typography>
                        <Box sx={{display:"flex", 
                                    flexDirection:"column", 
                                    backgroundColor:theme.palette.background.default, 
                                    borderRadius:"5px",
                                    marginTop:"10px",
                                    padding:"10px"}}>
                            <Typography variant='subtitle1' 
                                    fontWeight={300} 
                                    fontSize={"11pt"} 
                                    sx={{display:"flex",
                                    flexDirection:"row",
                                    alignItems:"center",
                                    gap:"10px",
                                    marginTop:"10px", marginBottom:"10px"}}
                                    >Test Performance: 
                                    <span style={{fontWeight:400, fontSize:"12pt"}}>
                                        {` ${modelData["last_test_performance"] ? `${(100 * modelData["last_test_performance"]).toFixed(2)}%` : null} ${modelData["optimization_metric"]}`}
                                    </span>
                                    <GradientCircularProgress value={(100 * modelData["last_test_performance"])} />
                            </Typography>
                            <hr />
                            <Typography variant='subtitle1' 
                                    fontWeight={300} 
                                    fontSize={"11pt"} 
                                    sx={{marginTop:"10px", marginBottom:"10px"}}
                                    >Algorithm:
                                    <span style={{fontWeight:400, fontSize:"12pt"}}>
                                        {` ${learningAlgorithmsDict[modelData["learning_algorithm"]]}`}
                                    </span>
                            </Typography>
                            <hr />
                            <Typography variant='subtitle1' 
                                    fontWeight={300} 
                                    fontSize={"11pt"} 
                                    sx={{marginTop:"10px", marginBottom:"10px"}}
                                    >Trained At:
                                    <span style={{fontWeight:400, fontSize:"12pt"}}>
                                        {` ${formatDate(modelData["last_trained_at"])}`}
                                    </span>
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{flex:1, 
                        display:"flex", 
                        flexDirection:"column", 
                        padding:"10px", 
                        backgroundColor:theme.palette.background.lighter,
                        border:"1px solid gray",
                        borderRadius:"5px"}}>
                        <Typography>Performance Metrics</Typography>
                        <Typography variant='subtitle2' fontWeight={300} fontSize={"9pt"}>Performane metrics of your machine learning model</Typography>
                        <Box sx={{display:"flex", 
                                    flexDirection:"column", 
                                    backgroundColor:theme.palette.background.default, 
                                    borderRadius:"5px",
                                    marginTop:"10px",
                                    padding:"10px"}}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {Object.keys(valResults).sort((a, b) => a - b).map(key => {
                                                return (<TableCell>{key.includes("/") ? key.split("/")[1] : key}</TableCell>)
                                            })}
                                        </TableRow>
                                        <TableRow>
                                            {Object.keys(valResults).map(key => {
                                                return (<TableCell sx={{backgroundColor: getGreenToRed(valResults[key] ? 100 * valResults[key].toFixed(2) : null)}}>{(100 * valResults[key]).toFixed(2)}%</TableCell>)
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{flex:1, 
                    display:"flex", 
                    flexDirection:"column", 
                    padding:"10px", 
                    marginTop: "20px",
                    backgroundColor:theme.palette.background.lighter,
                    border:"1px solid gray",
                    borderRadius:"5px"}}>
                    <Typography>Confusion Matrix</Typography>
                    <Typography variant='subtitle2' fontWeight={300} fontSize={"9pt"}>Your model performance accross classes.</Typography>
                    <Box sx={{display:"flex", 
                                flexDirection:"row", 
                                backgroundColor:theme.palette.background.default, 
                                borderRadius:"5px",
                                marginTop:"10px",
                                padding:"10px"}}>
                        <img src={`${BASE_URL}/${modelData["image_val_path"]}/confusion_matrix.png`} alt='Confusion matrix' style={{width:"50%"}} />
                        <img src={`${BASE_URL}/${modelData["image_val_path"]}/confusion_matrix_normalized.png`} alt='Confusion matrix normalized' style={{width:"50%"}} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const UseModel = ({modelData}) => {
    const theme = useTheme();
    const authToken = localStorage.getItem("authToken");

    const [predictedImages, setPredictedImages] = useState([]);
    const [confidences, setConfidences] = useState([]);
    const [classes, setClasses] = useState([]);
    const [names, setNames] = useState([]);

    const [open, setOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleOpen = (index) => {
        setCurrentImageIndex(index);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? predictedImages.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === predictedImages.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <Box sx={{border:"1px solid gray", 
            borderRadius:"5px", 
            margin:"20px", 
            padding:"20px" }}>
            <Typography variant='h6'>Use {modelData["name"]}</Typography>
            <Typography variant='subtitle1' fontWeight={300}>Upload images to make a prediction.</Typography>
            <Box sx={{display:"flex", flexDirection:"row", gap:"20px"}}>
                <Box sx={{flex:1, marginTop:2}}>
                    <ImagePredictModal modelData={modelData} setPredictedImages={setPredictedImages} setConfidences={setConfidences} setClasses={setClasses} setNames={setNames} />
                </Box>
                <Box sx={{flex:3, padding:1, margin:2, boxSizing:"border-box", border:"1px dashed gray"}}>
                    <Grid container spacing={2}>
                        {predictedImages.map((imgBase64, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <Card sx={{cursor: "pointer", position: "relative"}} onClick={() => handleOpen(index)}>
                                    <CardMedia
                                        component="img"
                                        alt={names[index]}
                                        height="140"
                                        image={`data:image/png;base64,${imgBase64}`}
                                        title={names[index]}
                                    />
                                    {modelData["target_type"] == "image_classification" ? (
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: "white",
                                                backgroundColor: "rgba(0, 0, 0, 0.5)",  // Optional: further darken the background
                                                textAlign: "center",
                                            }}
                                        >
                                            <Typography variant="h6">
                                                {classes[index]}
                                            </Typography>
                                            <Typography variant="h6">
                                                {confidences[index]}
                                            </Typography>
                                            <Typography variant='subtitle2' fontWeight={300} fontSize={"9pt"} color={"#cccccc"}>
                                                {names[index]}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <></>
                                    )} 
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>

            <Dialog open={open} onClose={handleClose} maxWidth="lg">
                <Box display="flex" flexDirection={"column"} justifyContent="center" alignItems="center" height="70vh" paddingX={"20px"}>
                    <Typography>{names[currentImageIndex]}</Typography>
                    <Box flex={3} display={"flex"} justifyContent="center" alignItems="center" height="70vh" padding={2} boxSizing={"border-box"}>
                        <img
                            src={`data:image/png;base64,${predictedImages[currentImageIndex]}`}
                            alt={`prediction-${currentImageIndex}`}
                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                        />
                    </Box>
                    <Box flex={0}>
                        {modelData["target_type"] == "image_classification" ? (
                            <Typography>Prediction: {classes[currentImageIndex]}, <br />Probability: {confidences[currentImageIndex]}</Typography>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Box>
                <DialogActions>
                    <Button onClick={handlePrev} disabled={currentImageIndex == 0}>
                        Prev
                    </Button>
                    <Button onClick={handleNext} disabled={currentImageIndex + 1 == predictedImages.length}>
                        Next
                    </Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )

}

const ImageModelOverview = () => {

    const authToken = localStorage.getItem('authToken');
    const location = useLocation();
    const model_id = location.state["model_id"];

    const [modelData, setModelData] = useState(null);

    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    }

    useEffect(() => {
        fetchModelOverview();
    }, [model_id]);

    const fetchModelOverview = () => {
        const url = model.getModelOverview(model_id);
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setModelData(data);
        })
    };

  return (
    <Box sx={{display:"flex", flexDirection:"column", width:"100%"}}>
        {
            modelData ? (
                <React.Fragment>
                    <Tabs value={currentTabIndex} onChange={handleTabChange} >
                        <Tab label='Overview' className='left' icon={<Analytics />}  iconPosition='start' />
                        <Tab label='Use Model' className='right' icon={<BatchPrediction />} iconPosition='start' />
                    </Tabs>
                
                    {/* Overview Tab */}
                    {currentTabIndex === 0 && (
                        <Overview modelData={modelData} />
                    )}
                    {/* Use Model Tab */}
                    {currentTabIndex === 1 && (
                        <UseModel modelData={modelData} />
                    )}
                </React.Fragment>
            ) : (
                <>

                </>
            )
        }
    </Box>
  )
}
export default ImageModelOverview