import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, List, ListItem, ListItemText, IconButton, ListItemAvatar, Avatar, LinearProgress, Snackbar, Alert, Select, MenuItem, InputLabel, FormControl, useTheme, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, Slide, Slider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { model } from '../../apis/urls';
import { postRequest } from "../../apis/requests";


const ImagePredictModal = ({ modelData, setPredictedImages, setConfidences, setClasses, setNames }) => {

    const authToken = localStorage.getItem("authToken");
    const theme = useTheme();

    const [images, setImages] = useState([]);
    const [conf, setConf] = useState(25);
    const [iou, setIou] = useState(70);
    const [uploading, setUploading] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    useEffect(() => {
        if (uploading) {
            const handleBeforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = 'Upload is in progress. Are you sure you want to leave?';
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [uploading]);

    const handleImageChange = (e) => {
        setImages([...e.target.files]);
    };

    const handleUpload = async () => {
        setUploading(true);
        const formData = new FormData();
        formData.append("conf", conf/100);
        formData.append("iou", iou/100);
        let totalSize = 0;
        for (let i = 0; i < images.length; i++) {
            totalSize += images[i].size / 1024;
            formData.append('images', images[i]);
        }

        if (totalSize > 5 * 1024) {
            setUploading(false);
            handleSnackbarOpen("error", "Total image size at a prediction cannot be larger than 5 MB on the platform. Use API instead.");
            return;
        }

        const url = model.predict_image(modelData["id"]);
        const headers = {
            "Authorization": authToken
        }

        postRequest(url, headers, formData)
          .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                return null;
            }
          })
          .then(data => {
            if(data){
                if(data["predicted_images"]) {
                    console.log(data);
                    setPredictedImages(data["predicted_images"].map(image => image["image_buffer"]));
                    setConfidences(data["predicted_images"].map(image => image["confidence"]));
                    setClasses(data["predicted_images"].map(image => image["class"]));
                    setNames(data["predicted_images"].map(image => image["name"]));
                    handleSnackbarOpen("success", "Predictions are ready.");
                    setUploading(false);
                }
                else {
                    handleSnackbarOpen("error", data["error"]);
                    setUploading(false);
                }
            }
            else{
                handleSnackbarOpen("error", "Error making prediction.");
                setUploading(false);
            }
          })    
    }

    const handleRemoveImage = (index) => {
        setImages(images.filter((_, i) => i !== index));
    };

    const formatSize = (size) => {
        const units = ['Bytes', 'KB', 'MB', 'GB'];
        let unitIndex = 0;
        let sizeValue = size;
        while (sizeValue >= 1024 && unitIndex < units.length - 1) {
            sizeValue /= 1024;
            unitIndex++;
        }
        return `${sizeValue.toFixed(2)} ${units[unitIndex]}`;
    };

    const getImagePreview = (image) => {
        return URL.createObjectURL(image);
    };

    return (
        <Box>
            <Box>
                <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    disabled={uploading}
                >
                    Select Images
                    <input
                        type="file"
                        multiple
                        hidden
                        onChange={handleImageChange}
                        disabled={uploading}
                    />
                </Button>
                <Box sx={{ height: 300, overflowY: 'auto', border: '1px solid #ccc', padding: 1 }}>
                    <List>
                        {Array.from(images).map((image, index) => (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveImage(index)} disabled={uploading}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar src={getImagePreview(image)} variant="square" />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={image.name}
                                    secondary={formatSize(image.size)}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
                {modelData["target_type"] == "object_detection" || modelData["target_type"] == "image_segmentation" ? (
                    <Box>
                        <FormControl fullWidth margin='dense'>
                            <Typography gutterBottom fontSize={12} marginLeft={"12px"} marginTop={"10px"} marginBottom={0} color={"#555555"}>
                                Confidence Threshold: {conf}%
                            </Typography>
                            <Slider
                                value={conf}
                                onChange={(e) => setConf(e.target.value)}
                                valueLabelDisplay="auto"
                                min={0}
                                max={100}
                                sx={{paddingY:"10px", marginTop:0}}
                            />
                        </FormControl>
                    </Box>
                ) : (
                    <></>
                )}

                {modelData["target_type"] == "object_detection" || modelData["target_type"] == "image_segmentation" ? (
                    <Box>
                        <FormControl fullWidth margin="dense">
                            <Typography gutterBottom fontSize={12} marginLeft={"12px"} marginTop={"10px"} marginBottom={0} color={"#555555"}>
                                Overlap (IoU) Threshold: {iou}%
                            </Typography>
                            <Slider
                                value={iou}
                                onChange={(e) => setIou(e.target.value)}
                                valueLabelDisplay="auto"
                                min={0}
                                max={100}
                                sx={{paddingY:"10px", marginTop:0}}
                            />
                        </FormControl>
                    </Box>
                ) : (
                    <></>
                )}
                
                {uploading && (
                    <Box sx={{ width: '100%', marginTop: 2 }}>
                        <CircularProgress />
                    </Box>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2 }}
                    onClick={handleUpload}
                    disabled={uploading}
                >
                    {uploading ? `Processing...` : 'Predict'}
                </Button>
            </Box>
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    variant='filled'
                    sx={{width:"100%"}}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ImagePredictModal;
