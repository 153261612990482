import { Add, Delete, ManageSearch, MoreVert, PlayCircleOutline, ReadMore, Share } from '@mui/icons-material'
import { Alert, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, Snackbar, Tooltip, Typography, useTheme } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'

import { getRequest, deleteRequest, postRequest, putRequest } from "../apis/requests";
import { model, team } from "../apis/urls";
import styled from '@emotion/styled';
import { formatDate } from '../utils';
import { useNavigate } from 'react-router-dom';
import ModelBuildModal from '../components/model/ModelBuildModal';

const PageContainer = styled(Box)(( { theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 30,
}));

const taskTypesDict = {
    "classification": "CLASSIFICATION",
    "regression": "REGRESSION",
    "image_classification": "IMAGE CLASSIFICATION",
    "object_detection": "OBJECT DETECTION",
    "image_segmentation": "IMAGE SEGMENTATION",
}

const optimizationMetricsDict = {
    "accuracy": "ACCURACY",
    "log_loss": "LOG LOSS",
    "f1": "F1 SCORE",
    "roc_auc": "ROC AUC",
    "mae": "MAE",
    "mse": "MSE",
    "rmse": "RMSE",
    "r2": "R2 SCORE",
    "map": "mAP"
}

const learningAlgorithmsDict = {
    "logistic_regression": "LOGISTIC REGRESSION",
    "k_neighbors_classifier": "K-NEIGHBORS",
    "support_vector_classifier": "SVM",
    "decision_tree_classifier": "DECISION TREE",
    "random_forest_classifier": "RANDOM FORESTS",
    "gradient_boosting_classifier": "GBM",
    "ada_boost_classifier": "ADA BOOST",
    "naive_bayes_classifier": "NAIVE BAYES",
    "mlp_classifier": "MLP",
    "linear_regression": "LINEAR REGRESSION",
    "lasso_regression": "LASSO",
    "elastic_net": "ELASTIC NET",
    "k_neighbors_regressor": "K-NEIGHBORS",
    "support_vector_regressor": "SVM",
    "decision_tree_regressor": "DECISION TREE",
    "random_forest_regressor": "RANDOM FORESTS",
    "gradient_boosting_regressor": "GBM",
    "mlp_regressor": "MLP",
    "yolov8": "YOLO v8",
    "yolov9": "YOLO v9",
    "yolov10": "YOLO v10"
}

const Models = () => {

    const authToken = localStorage.getItem('authToken');
    const [selectedItem, setSelectedItem] = useState(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [startTrainingDialogOpen, setStartTrainingDialogOpen] = useState(false);
    const [shareModelDialogOpen, setShareModelDialogOpen] = useState(false);

    const [gridData, setGridData] = useState(null);

    const [trainModelModalOpen, setTrainModelModalOpen] = useState(false);
    const handleOpenTrainModelModal = () => setTrainModelModalOpen(true);
    const handleCloseTrainModelModal = () => {
        setTrainModelModalOpen(false);
        fetchModels();
      }

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const navigate = useNavigate();

    const DeleteDialog = () => {

        const theme = useTheme();
    
        return (
          <Dialog
            open={dialogOpen}
            onClose={() => handleDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Are you sure want to delete this model?"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                  When you delete a model you will not be able to work with it anymore. Be sure before perform this action.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleDialogClose(true)}>Delete</Button>
            </DialogActions>
          </Dialog>
        )
      }

    const handleDialogClose = (deleteApproved) => {
        if(deleteApproved){
            const url = model.delete(selectedItem);
            deleteRequest(url, {"Authorization": authToken})
                .then(response => {
                    if (response.ok) {
                        handleSnackbarOpen("success", "Model has been deleted successfully.");
                        fetchModels();
                    }
                    else{
                        return response.json();
                    }
                })
                .then(data => {
                    if (data && data["error"]){
                        handleSnackbarOpen("error", data["error"]);
                    }
                });
        }
        setDialogOpen(false);
    };

    const StartTrainingDialog = () => {

        const theme = useTheme();
    
        return (
          <Dialog
            open={startTrainingDialogOpen}
            onClose={() => handleStartTrainingDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Training will be started."}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                  Training will be started. Do you confirm?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleStartTrainingDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleStartTrainingDialogClose(true)}>Start</Button>
            </DialogActions>
          </Dialog>
        )
    };

    const handleStartTrainingDialogClose = (startApproved) => {
        if(startApproved){              
            const url = model.startTraining(selectedItem);

            postRequest(url, {"Authorization": authToken})
                .then(response => {
                    if(response.ok) {
                        const updatedGridData = gridData.map(item => {
                            if (item.id === selectedItem) {
                                return { ...item, status: "IN_PROGRESS" };
                            }
                            return item;
                        });
                    
                        setGridData(updatedGridData);
                        handleSnackbarOpen("success", "Model training has been started. You'll be notified when it finishes.");
                        fetchModels();
                    }
                    else {
                        return response.json();
                    }
                })
                .then(data => {
                    if (data) {
                        if(data["error"]){
                            handleSnackbarOpen("error", data["error"]);
                        }
                    }
                });
        }
        setStartTrainingDialogOpen(false);
    };

    const ShareModelDialog = () => {

        const theme = useTheme();
        const [teamData, setTeamData] = useState([]);
        const [selectedTeam, setSelectedTeam] = useState(null);

        useEffect(() => {
            const url = team.getByUserId();
            getRequest(url, {"Authorization": authToken})
                .then(data => {
                    setTeamData(data);
                })
        }, [])
        
    
        return (
          <Dialog
            open={shareModelDialogOpen}
            onClose={() => handleShareModelDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Choose a team that you want to share the model with"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <Box>
                    {(teamData && teamData.length > 0) ? (
                        <FormControl fullWidth margin='normal'>
                            <InputLabel>Select team</InputLabel>
                            <Select
                                label={"Select team"}
                                value={selectedTeam}
                                sx={{width:"400px", padding:0 }}
                                onChange={(event) => {setSelectedTeam(event.target.value)}}
                                >
                                {teamData.map(team => {
                                    return (
                                        <MenuItem value={team}>{team.name}</MenuItem>
                                    )}
                                )}
                            </Select>
                        </FormControl>
                    ) : 
                    (
                        <Typography>You do not have a team</Typography>
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleShareModelDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleShareModelDialogClose(true, selectedTeam)}>Share</Button>
            </DialogActions>
          </Dialog>
        )
    };

    const handleShareModelDialogClose = (shareApproved, selectedTeam) => {
        if(shareApproved){
            if (selectedTeam) {
                const url = model.share(selectedItem);
                const headers = {
                    "Content-Type": "application/json",
                    "Authorization": authToken
                }
                const body = JSON.stringify({
                    "team_id": selectedTeam.id
                })

                putRequest(url, headers, body)
                    .then(response => {
                        if(response.ok){
                            handleSnackbarOpen("success", "Model has been shared with the team.");
                        }
                        return response.json();
                    })
                    .then(data => {
                        if(data["error"]){
                            handleSnackbarOpen("error", data["error"]);
                        }
                    });
            }         
            else {
                handleSnackbarOpen("error", "You must select a team for sharing.")
            }
        }
        setShareModelDialogOpen(false);
    };

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    useEffect(() => {
        fetchModels();
        const intervalId = setInterval(() => {
            fetchModels();
        }, 10000);
        
        return () => clearInterval(intervalId);
    }, []);

    const fetchModels = () => {
        const url = model.getByUserId();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setGridData(data);
            })
    };

    const columns = [
        { field: 'name', headerName: 'Name', width: "200" },
        { field: 'data_type', headerName: 'Data Type', width: "120", renderCell: (params) => {
            if (["classification", "regression"].includes(params.row["target_type"])){
                return (<Chip label={"TABULAR"} sx={{backgroundColor:"lavender"}} />);
            }
            else if (["image_classification", "object_detection", "image_segmentation"].includes(params.row["target_type"])){
                return (<Chip label={"IMAGE"} sx={{backgroundColor:"#ffe6ee"}} />);
            }
        } },
        { field: 'target_type', headerName: 'Type', width: "170", renderCell: (params) => (taskTypesDict[params.row["target_type"]]) },
        { field: 'target_feature', headerName: 'Target', width: "100" },
        { field: 'learning_algorithm', headerName: 'Algorithm', width: "140", renderCell: (params) => (learningAlgorithmsDict[params.row["learning_algorithm"]]) },
        { field: 'optimization_metric', headerName: 'Optimization', width: "120", renderCell: (params) => (optimizationMetricsDict[params.row["optimization_metric"]]) },
        { field: 'last_test_performance', headerName: 'Test Score', width: "100", renderCell: (params) => {
            if (params.row["last_test_performance"]){
                return params.row["last_test_performance"].toFixed(4);
            }
            else {
                return params.row["last_test_performance"];
            }
        } },
        { field: 'status', headerName: 'Status', width: "130", renderCell: (params) => {
            if (params.row["status"] == "NOT_STARTED") {
                return (<Chip label={"NOT STARTED"} />)
            }
            else if (params.row["status"] == "IN_PROGRESS") {
                return (<Chip label={"IN PROGRESS"} color='info' />)
            }
            else if (params.row["status"] == "COMPLETED") {
                return (<Chip label={"COMPLETED"} color='success' />)
            }
            else if (params.row["status"] == "FAILED") {
                return (<Chip label={"FAILED"} color='warning' />)
            }
        }},
        { field: 'last_trained_at', headerName: 'Completed At', width: "130", renderCell: (params) => (params.row["last_trained_at"] ? formatDate(params.row["last_trained_at"]) : null) },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: "245",
            renderCell: (params) => {return (
                <Box>
                    <Tooltip title="Overview">
                        <IconButton aria-label='Overview' onClick={() => handleClickAction(params.row, "overview")}>
                            <ManageSearch sx={{color:"#ffbf00", width:"35px", height:"35px"}} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Start Training">
                        <IconButton color='success' aria-label='Overview' onClick={() => handleClickAction(params.row, "train_model")}>
                            <PlayCircleOutline sx={{width:"35px", height:"35px"}} />
                        </IconButton>
                        </Tooltip>
                    <Tooltip title="Share">
                        <IconButton color='primary' aria-label='Overview' onClick={() => handleClickAction(params.row, "share")}>
                            <Share sx={{width:"30px", height:"30px"}} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton color='error' aria-label='Overview' onClick={() => handleClickAction(params.row, "delete")}>
                            <Delete sx={{width:"30px", height:"30px"}} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        }
    ]

    const handleClickAction = (props, action_type) => {
        setSelectedItem(props.id);

        if (action_type === "delete") {
          setDialogOpen(true);
        }
        else if (action_type === "overview"){
          if(props.status == "COMPLETED"){
            if(["classification", "regression"].includes(props["target_type"])){
                navigate("/platform/models/overview", { state: { model_id: props.id } });
            }
            else if(["image_classification", "object_detection", "image_segmentation"].includes(props["target_type"])){
                navigate("/platform/models/image_overview", { state: { model_id: props.id } });
            }
          }
          else {
            handleSnackbarOpen("error", "Model must be successfully trained before overviewing.")
          }
        }
        else if (action_type === "train_model"){
          setStartTrainingDialogOpen(true);
        }
        else if (action_type === "share"){
          setShareModelDialogOpen(true);
        }
      }

  return (
    <Box sx={{ width: "100%" }}>
        <Typography variant='h5'>Models</Typography>
        <PageContainer>
            <Box sx={{ margin: "0px 0px 20px 0px"}}>
                <Button
                    variant="contained"
                    endIcon={<Add />}
                    onClick={handleOpenTrainModelModal}
                >Train a Model</Button>
            </Box>
            {gridData ? (
                <Box sx={{ height: "500px" }}>
                    <DataGrid
                        rows={gridData}
                        columns={columns}
                        checkboxSelection
                        disableRowSelectionOnClick
                        isRowSelectable={() => false}
                        style={{fontWeight: 400, fontSize:"11pt"}}
                    />
                </Box>
            ) : (
                <Box sx={{ width: "100%", height:"50vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            )}
        </PageContainer>
        <DeleteDialog open={dialogOpen} handleClose={handleDialogClose} />
        <StartTrainingDialog open={startTrainingDialogOpen} handleClose={handleStartTrainingDialogClose} />
        <ShareModelDialog open={shareModelDialogOpen} handleClose={handleShareModelDialogClose} />
        <ModelBuildModal open={trainModelModalOpen} handleClose={handleCloseTrainModelModal} />
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default Models