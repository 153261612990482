import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import HomeNavbar from '../components/home/HomeNavbar'
import Footer from '../components/home/Footer'
import BlogPost1 from '../components/blog/posts/BlogPost1'

const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])

    return (
        <Box>
            <HomeNavbar />
            <Box>
                <BlogPost1 />
            </Box>
            <Footer />
        </Box>
    )
}

export default Blog