import { Box, CircularProgress, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { formatDate } from '../../utils';

const getGreenToRed = (percent) => {
    if (percent) {
        let r = percent<70 ? 255 : Math.floor(255-(percent*2-100)*255/140);
        let g = percent>70 ? 200 : Math.floor((percent*2)*200/140);
        return 'rgb('+r+','+g+',0)';
    }
    return "#eeeeee";
}

const learningAlgorithmsDict = {
    "logistic_regression": "LOGISTIC REGRESSION",
    "k_neighbors_classifier": "K-NEIGHBORS",
    "support_vector_classifier": "SVM",
    "decision_tree_classifier": "DECISION TREE",
    "random_forest_classifier": "RANDOM FORESTS",
    "gradient_boosting_classifier": "GBM",
    "ada_boost_classifier": "ADA BOOST",
    "naive_bayes_classifier": "NAIVE BAYES",
    "mlp_classifier": "MLP",
    "linear_regression": "LINEAR REGRESSION",
    "lasso_regression": "LASSO",
    "elastic_net": "ELASTIC NET",
    "k_neighbors_regressor": "K-NEIGHBORS",
    "support_vector_regressor": "SVM",
    "decision_tree_regressor": "DECISION TREE",
    "random_forest_regressor": "RANDOM FORESTS",
    "gradient_boosting_regressor": "GBM",
    "mlp_regressor": "MLP",
    "yolov8": "YOLO v8",
    "yolov9": "YOLO v9",
    "yolov10": "YOLO v10"
}

const GradientCircularProgress = ({ value }) => {
    
  
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          {/* Background Circular Progress (gray) */}
          <CircularProgress
            variant="determinate"
            value={100}
            thickness={5}
            sx={{
              color: '#eeeeee', // Gray color for the background
              position: 'absolute',
              left: 0,
            }}
          />
          {/* Foreground Circular Progress (color based on value) */}
          <CircularProgress
            variant="determinate"
            value={value}
            thickness={5}
            sx={{
              color: getGreenToRed(value),
            }}
          />
        </Box>
      );
  };


const Summary = ({modelData}) => {

    const theme = useTheme();

    const sortedObj = Object.entries(modelData["feature_importances"])
        .sort((a, b) => Math.abs(b[1]) - Math.abs(a[1]))
        .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});

    const totalImportance = Object.values(sortedObj).reduce((a, b) => Math.abs(a) + Math.abs(b));

  return (

    <Box sx={{display:"flex", flexDirection:"row", gap:"20px", marginTop:"20px"}}>
        <Box sx={{flex:1, 
            display:"flex", 
            flexDirection:"column", 
            padding:"10px", 
            backgroundColor:theme.palette.background.lighter,
            border:"1px solid gray",
            borderRadius:"5px"}}>
            <Typography>Overview</Typography>
            <Typography variant='subtitle2' fontWeight={300} fontSize={"11pt"}>Overview of your machine learning model</Typography>
            <Box sx={{display:"flex", 
                        flexDirection:"column", 
                        backgroundColor:theme.palette.background.default, 
                        borderRadius:"5px",
                        marginTop:"10px",
                        padding:"10px",
                        height:"100%"}}>
                <Typography variant='subtitle1' 
                        fontWeight={300}
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={"10px"}
                        fontSize={"11pt"} 
                        sx={{marginTop:"10px", marginBottom:"10px"}}
                        >Test Performance: 
                        <span style={{fontWeight:400, fontSize:"12pt"}}>
                            {` ${modelData["last_test_performance"] ? (["accuracy", "f1", "roc_auc"].includes(modelData["optimization_metric"]) ? `${(100 * modelData["last_test_performance"]).toFixed(2)}%` : `${(modelData["last_test_performance"]).toFixed(2)}`) : null} ${modelData["optimization_metric"]}`}
                        </span>
                        {["accuracy", "f1", "roc_auc"].includes(modelData["optimization_metric"]) ? (
                            <GradientCircularProgress value={(100 * modelData["last_test_performance"])} />
                        ) : (
                            <></>
                        )}
                        
                </Typography>
                <hr />
                <Typography variant='subtitle1' 
                        fontWeight={300} 
                        fontSize={"11pt"} 
                        sx={{marginTop:"10px", marginBottom:"10px"}}
                        >Algorithm:
                        <span style={{fontWeight:400, fontSize:"12pt"}}>
                            {` ${learningAlgorithmsDict[modelData["learning_algorithm"]]}`}
                        </span>
                </Typography>
                <hr />
                <Typography variant='subtitle1' 
                        fontWeight={300} 
                        fontSize={"11pt"} 
                        sx={{marginTop:"10px", marginBottom:"10px"}}
                        >Trained At:
                        <span style={{fontWeight:400, fontSize:"12pt"}}>
                            {` ${formatDate(modelData["last_trained_at"])}`}
                        </span>
                </Typography>
            </Box>
        </Box>
        <Box sx={{flex:1, 
            display:"flex", 
            flexDirection:"column", 
            padding:"10px", 
            backgroundColor:theme.palette.background.lighter,
            border:"1px solid gray",
            borderRadius:"5px"}}>
            <Typography>Top Drivers</Typography>
            <Typography variant='subtitle2' fontWeight={300} fontSize={"11pt"}>Top fields that the model relies on the most to make a prediction</Typography>
            <Box sx={{display:"flex", 
                        flexDirection:"column", 
                        backgroundColor:theme.palette.background.default, 
                        borderRadius:"5px",
                        marginTop:"10px",
                        padding:"10px",
                        height:"100%"}}>
                <Typography variant='subtitle1' 
                        fontWeight={300} 
                        fontSize={"11pt"} 
                        sx={{marginTop:"10px", marginBottom:"10px"}}
                        >1.{Object.keys(sortedObj)[0]}: 
                        <span style={{fontWeight:400, fontSize:"12pt"}}>
                            {` ${((Object.values(sortedObj)[0] / totalImportance)*100) ? ((Object.values(sortedObj)[0] / totalImportance)*100).toFixed(2) : null}%`}
                            <LinearProgress variant="determinate" value={((Object.values(sortedObj)[0] / totalImportance)*100)} />
                        </span>
                </Typography>
                <hr />
                <Typography variant='subtitle1' 
                        fontWeight={300} 
                        fontSize={"11pt"} 
                        sx={{marginTop:"10px", marginBottom:"10px"}}
                        >2.{Object.keys(sortedObj)[1]}:
                        <span style={{fontWeight:400, fontSize:"12pt"}}>
                            {` ${((Object.values(sortedObj)[1] / totalImportance)*100) ? ((Object.values(sortedObj)[1] / totalImportance)*100).toFixed(2) : null}%`}
                            <LinearProgress variant="determinate" value={((Object.values(sortedObj)[1] / totalImportance)*100)} />
                        </span>
                </Typography>
                <hr />
                <Typography variant='subtitle1' 
                        fontWeight={300} 
                        fontSize={"11pt"} 
                        sx={{marginTop:"10px", marginBottom:"10px"}}
                        >3.{Object.keys(sortedObj)[2]}: 
                        <span style={{fontWeight:400, fontSize:"12pt"}}>
                            {` ${((Object.values(sortedObj)[2] / totalImportance)*100) ? ((Object.values(sortedObj)[2] / totalImportance)*100).toFixed(2) : null}%`}
                            <LinearProgress variant="determinate" value={((Object.values(sortedObj)[2] / totalImportance)*100)} />
                        </span>
                </Typography>
            </Box>
        </Box>
        <Box sx={{flex:1, 
            display:"flex", 
            flexDirection:"column", 
            padding:"10px", 
            backgroundColor:theme.palette.background.lighter,
            border:"1px solid gray",
            borderRadius:"5px"}}>
            <Typography>Performance Metrics</Typography>
            <Typography variant='subtitle2' fontWeight={300} fontSize={"11pt"}>Performane metrics of your machine learning model</Typography>
            <Box sx={{display:"flex", 
                        flexDirection:"column", 
                        backgroundColor:theme.palette.background.default, 
                        borderRadius:"5px",
                        marginTop:"10px",
                        padding:"10px",
                        maxHeight:"180px"}}>
                {modelData["target_type"] == "classification" ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Class</TableCell>
                                    <TableCell>Precision</TableCell>
                                    <TableCell>Recall</TableCell>
                                    <TableCell>Specificity</TableCell>
                                    <TableCell>F1 Score</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(modelData["metrics_by_class"]).map(entry => {
                                    return (
                                        <TableRow>
                                            <TableCell>{entry[0]}</TableCell>
                                            <TableCell sx={{backgroundColor: getGreenToRed(entry[1]["precision"] ? 100 * entry[1]["precision"].toFixed(2) : null)}}>{entry[1]["precision"] ? entry[1]["precision"].toFixed(2) : null}</TableCell>
                                            <TableCell sx={{backgroundColor: getGreenToRed(entry[1]["recall"] ? 100 * entry[1]["recall"].toFixed(2) : null)}}>{entry[1]["recall"] ? entry[1]["recall"].toFixed(2) : null}</TableCell>
                                            <TableCell sx={{backgroundColor: getGreenToRed(entry[1]["specificity"] ? 100 * entry[1]["specificity"].toFixed(2) : null)}}>{entry[1]["specificity"] ? entry[1]["specificity"].toFixed(2) : null}</TableCell>
                                            <TableCell sx={{backgroundColor: getGreenToRed(entry[1]["f1_score"] ? 100 * entry[1]["f1_score"].toFixed(2) : null)}}>{entry[1]["f1_score"] ? entry[1]["f1_score"].toFixed(2) : null}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>MAE</TableCell>
                                    <TableCell>RMSE</TableCell>
                                    <TableCell>R2</TableCell>
                                    <TableCell>Explained Variance</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(modelData["metrics_by_class"]).map(entry => {
                                    return (
                                        <TableRow>
                                            <TableCell>{entry[1]["mae"] ? entry[1]["mae"].toFixed(2) : null}</TableCell>
                                            <TableCell>{entry[1]["rmse"] ? entry[1]["rmse"].toFixed(2) : null}</TableCell>
                                            <TableCell>{entry[1]["r2"] ? entry[1]["r2"].toFixed(2) : null}</TableCell>
                                            <TableCell>{entry[1]["explained_variance"] ? entry[1]["explained_variance"].toFixed(2) : null}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </Box>
    </Box>
  )
}

export default Summary