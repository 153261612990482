import { Box } from "@mui/material";
import Navbar from "./components/global/Navbar";
import Sidebar from "./components/global/Sidebar";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";

import styled from "@emotion/styled";
import Datasets from "./pages/Datasets";
import { AuthProvider } from "./auth/AuthContext";
import { ProtectedRoute } from "./auth/ProtectedRoute"
import Signup from "./pages/Signup";
import PostSignup from "./pages/PostSignup";
import Activate from "./pages/Activate";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import Models from "./pages/Models";
import ModelOverview from "./pages/ModelOverview";
import Profile from "./pages/Profile";
import Account from "./pages/Account";
import Team from "./pages/Team";
import Apis from "./pages/Apis";
import Images from "./pages/Images";
import ApiOverview from "./pages/ApiOverview";
import ImageDatasets from "./pages/ImageDatasets";
import ImageDatasetOverview from "./pages/ImageDatasetOverview";
import DatasetOverview from "./pages/DatasetOverview";
import Home from "./pages/Home";
import Service from "./pages/Service";
import ProjectManagement from "./pages/ProjectManagement";
import ImageModelOverview from "./pages/ImageModelOverview";
import Pricing from "./pages/Pricing";
import Demo from "./pages/Demo";
import UseCase from "./pages/UseCase";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Help from "./pages/Help";
import Blog from "./pages/Blog";

const PageContent = styled(Box)(( { theme }) => ({
  flex: 6,
  display: "flex",
  padding: "10px",
}));

function MainLayout() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Sidebar />
        <PageContent sx={{ flexGrow: 1, marginTop: 3, overflow: 'hidden' }}>
          <Outlet />
        </PageContent>
      </Box>
    </Box>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service" element={<Service />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/request_demo" element={<Demo />} />
          <Route path="/use_case" element={<UseCase />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/platform/login" element={<Login />} />
          <Route path="/platform/signup" element={<Signup />} />
          <Route path="/platform/post-signup" element={<PostSignup />} />
          <Route path="/user/activate/:uidb64/:token">
            <Route index element={<Activate />} />
          </Route>
          <Route path="/platform/forgot-password" element={<ForgotPassword />} />
          <Route path="user/reset_password_confirm/:uidb64/:token">
            <Route index element={<ResetPassword />} />
          </Route>

          <Route path="/platform" element={<ProtectedRoute><MainLayout /></ProtectedRoute>}>
            <Route index element={<Dashboard />} />
            <Route path="help" element={<Help />} />
            <Route path="datasets">
              <Route index element={<Datasets />} />
              <Route path="overview" element={<DatasetOverview />} />
            </Route>
            <Route path="models">
              <Route index element={<Models />} />
              <Route path="overview" element={<ModelOverview />} />
              <Route path="image_overview" element={<ImageModelOverview />} />
            </Route>
            <Route path="profile" element={<Profile />} />
            <Route path="account" element={<Account />} />
            <Route path="project_management" element={<ProjectManagement />} />
            <Route path="team_management" element={<Team />} />
            <Route path="apis">
              <Route index element={<Apis />} />
              <Route path="overview" element={<ApiOverview />} />
            </Route>
            <Route path="image_datasets">
              <Route index element={<ImageDatasets />} />
              <Route path="overview" element={<ImageDatasetOverview />} />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
